import * as React from 'react'
import Layout from '/src/components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const SkaffaElbilPage = () => {
    return (
        <Layout pageTitle="Skaffa elbil">

			<p className="intro">Laddify är ett verktyg framförallt för dig som redan har elbil och letar laddplats. Förhoppningsvis kan Laddify även bidra till att fler byter till elbil och då kan kanske några länktips kan vara till hjälp (utan inbördes ordning).</p>


			<h2>Webbsidor, tidningar, bloggar &amp; YouTube-kanaler</h2>
			<ul>
				<li><a href="https://teslaclubsweden.se" target="_blank" rel="noreferrer">Tesla Club Sweden <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
				<li><a href="https://iterik.se/tag/elbil/" target="_blank" rel="noreferrer">ITeriks blogg (länk till inlägg i ämnet) <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
			</ul>


			<h2>Guider</h2>
			<ul>
				<li><a href="https://teslaclubsweden.se/skaffa-elbil/" target="_blank" rel="noreferrer">Boken "Skaffa elbil"  <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
				<li><a href="https://alltomelbil.se/tio-steg-till-att-kopa-elbil/" target="_blank" rel="noreferrer">Allt om elbil's 10 steg <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
				<li><a href="https://iterik.se/tag/elbil/" target="_blank" rel="noreferrer">ITeriks blogg (länk till inlägg i ämnet) <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
			</ul>


			<h2>Facebook-grupper</h2>
			<ul>
				<li><a href="https://www.facebook.com/groups/teslasverige/" target="_blank" rel="noreferrer">Tesla Ägare/Entusiaster Sverige <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
				<li><a href="https://www.facebook.com/groups/Elbil.och.laddhybridbil.i.Sverige/" target="_blank" rel="noreferrer">Elbil och laddhybridbil i Sverige <FontAwesomeIcon icon={faExternalLinkAlt} style={{color:'#999', fontSize: '12px'}} /></a></li>
			</ul>

        </Layout>
    )
}

export default SkaffaElbilPage